import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import PropTypes from 'prop-types';
import React from 'react';

import Disclaimer from '../components/framework/disclaimer';

import Faq from '../components/framework/faq';
import ContainerDonts from '../components/framework/container-donts';
import Framework from '../components/framework';
import Seo from '../components/framework/seo';

const Supportpage = ({ data, location }) => {
	const support_items = data.allSupport.edges;
	const { t } = useTranslation('support');

	return (
		<Framework location={location}>
			<Seo
				description={t('meta_description')}
				tags={t('meta_keywords')}
				title={t('title')}
			/>

			<section className="faq">
				<div className="container mt-lg-5 pt-5">
					<h1 className="h2 mb-3 mb-lg-4">{t('t1')}</h1>
					<p className="small">{t('t2')}</p>
					<Faq items={support_items} />
					<ContainerDonts />
					<div className="mt-3 mt-lg-5">
						<Disclaimer />
					</div>
				</div>
			</section>
		</Framework>
	);
};

Supportpage.propTypes = {
	data: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
};

export default Supportpage;

export const query = graphql`
	query {
		allSupport {
			edges {
				node {
					translation_key
				}
			}
		}

		locales: allTranslation(filter: { id: { ne: "dummy" } }) {
			...translationFields
		}
	}
`;
